/*eslint-disable*/
import Dashboard from "./pages/dashboard";
import Rooms from "./pages/rooms";
// import Highlights from "./pages/highlights";
import Bookings from "./pages/bookings";
// import Form from "./pages/form";
// import Calendar from "./pages/calendar";
// import Facilities from "./pages/facilities";
// import Bar from "./pages/bar";
// import Line from "./pages/line";
// import Pie from "./pages/pie";
// import FAQ from "./pages/faq";
// import Geography from "./pages/geography";

export const adminRoutes = [
    // {
    //     path: "/dashboard",
    //     component: <Dashboard />,
    // },
    {
        path: "/users",
        component: <Rooms />,
    },
    {
        path: "/games",
        component: <Bookings />,
    },
    // {
    //     path: "/highlights",
    //     component: <Highlights />,
    // },
    // {
    //     path: "/facilities",
    //     component: <Facilities />,
    // },
    // {
    //     path: "/form",
    //     component: <Form />,
    // },
    // {
    //     path: "/calendar",
    //     component: <Calendar />,
    // },
]