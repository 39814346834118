/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Stack,
    TextField,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
// import { mockDataContacts } from "../../data/mockData";

import Header from "../../components/Header";
import { useSelector } from "react-redux";
import { addNewBooking, listAllRooms, resetAllBookingsData, listAllBookings, resetAllRoomsList } from "../../store/actions";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { Add } from "@mui/icons-material";
import * as yup from "yup";

const Bookings = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [roomsBooked, setRoomsBooked] = useState([]);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const { isLoading, bookingsList, rooms, showViewMore } = useSelector(
        ({
            loading,
            admin: {
                bookings: { list, showViewMore },
                rooms,
            },
        }) => ({
            isLoading: loading,
            bookingsList: list,
            showViewMore,
            rooms,
        })
    );

    useEffect(() => {
        dispatch(resetAllBookingsData());
        dispatch(resetAllRoomsList());
        fetchMoreData();
    }, []);

    const fetchMoreData = () => {
        dispatch(listAllBookings());
        dispatch(listAllRooms());
    };

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: smScreen ? 400 : "90%",
        bgcolor: "#1F2A40",
        outline: "none",
        boxShadow: 24,
        p: 2,
    };

    const handleFormSubmit = async (values) => {
        let data = {
            ...values,
            users: roomsBooked,
        };

        await dispatch(addNewBooking(data));
        setRoomsBooked([]);
        handleClose();
    };
    const initialValues = {
        // name: "",
        // email: "",
        // totalPrice: 0,
    };
    const checkoutSchema = yup.object().shape({
        // name: yup.string().required("Required"),
        // email: yup.string().email("Invalid email").required("Required"),
    });

    const columns = [
        { field: "_id", headerName: "Game Id", width: 250 },
        {
            field: "status",
            headerName: "Status",
            width: 100,
        },
    ];
    return (
        <Box m="20px">
            <Box
                display={smScreen ? "flex" : "block"}
                flexDirection={smScreen ? "row" : "column"}
                justifyContent={smScreen ? "space-between" : "start"}
                alignItems={smScreen ? "center" : "start"}
                m="20px 0"
            >
                <Header title="GAMES" subtitle="welcome to Games" />

                <Box>
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                        onClick={handleOpen}
                    >
                        <Add sx={{ mr: "10px" }} />
                        Host a game
                    </Button>
                </Box>
            </Box>
            <Box
                m="8px 0 0 0"
                width="100%"
                height="80vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={bookingsList}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    getRowId={(row) => row?._id}
                />
            </Box>

            <Modal open={open} onClose={handleClose}>
                <Box sx={modalStyle}>
                    <Header title="HOST A GAME" titleVariant="h4" />

                    <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={checkoutSchema}>
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <Stack spacing={2}>
                                    {/* <TextField
                                        fullWidth
                                        size="small"
                                        variant="filled"
                                        type="text"
                                        label="Name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.name}
                                        name="name"
                                        error={!!touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                    />
                                    <TextField
                                        fullWidth
                                        size="small"
                                        variant="filled"
                                        type="text"
                                        label="Email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.email}
                                        name="email"
                                        error={!!touched.email && !!errors.email}
                                        helperText={touched.email && errors.email}
                                    /> */}
                                    {/* <Stack>
                                        <InputLabel>Checkin Date</InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            variant="filled"
                                            type="date"
                                            placeholder="Checkin Date"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.checkinDate}
                                            inputProps={{ min: moment().format("YYYY-MM-DD") }}
                                            // defaultValue={values.checkinDate}
                                            name="checkinDate"
                                            error={!!touched.checkinDate && !!errors.checkinDate}
                                            helperText={touched.checkinDate && errors.checkinDate}
                                        />
                                    </Stack>
                                    <Stack>
                                        <InputLabel>Checkout Date</InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            variant="filled"
                                            type="date"
                                            placeholder="Checkout Date"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.checkoutDate}
                                            inputProps={{ min: values.checkinDate }}
                                            // defaultValue={values.checkoutDate}
                                            name="checkoutDate"
                                            error={!!touched.checkoutDate && !!errors.checkoutDate}
                                            helperText={touched.checkoutDate && errors.checkoutDate}
                                        />
                                    </Stack> */}
                                    {/* <TextField
                                        fullWidth
                                        size="small"
                                        variant="filled"
                                        type="number"
                                        label="Total Price"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.totalPrice}
                                        name="totalPrice"
                                        error={!!touched.totalPrice && !!errors.totalPrice}
                                        helperText={touched.totalPrice && errors.totalPrice}
                                    /> */}
                                    <FormControl size="small" fullWidth>
                                        <InputLabel>Select Users</InputLabel>
                                        <Select
                                            multiple
                                            value={roomsBooked}
                                            label="Rooms"
                                            onChange={(e) => setRoomsBooked(e.target.value)}
                                        >
                                            {rooms?.list?.map((e, i) => (
                                                <MenuItem value={e._id} key={i}>
                                                    <Checkbox checked={roomsBooked.indexOf(e._id) > -1} />
                                                    {e.username}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        color="secondary"
                                        variant="contained"
                                        sx={{ fontSize: "15px" }}
                                    >
                                        {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Submit</b>}
                                    </Button>
                                </Stack>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Modal>
        </Box>
    );
};

export default Bookings;
