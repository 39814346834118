/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, CircularProgress, FormControl, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
// import { mockDataRooms } from "../../data/mockData";
// import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
// import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
// import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import { useSelector } from "react-redux";
import { addNewRoom, listAllRooms, resetAllRoomsList } from "../../store/actions";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { Add } from "@mui/icons-material";

const Rooms = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { isLoading, roomsList, showViewMore } = useSelector(
    ({
      loading,
      admin: {
        rooms: { list, showViewMore },
      },
    }) => ({
      isLoading: loading,
      roomsList: list,
      showViewMore,
    })
  );
  
  useEffect(() => {
    dispatch(resetAllRoomsList());
    fetchMoreData();
  },[dispatch]);

  const fetchMoreData = () => {
    dispatch(listAllRooms());
  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: smScreen ? 400 : "90%",
    bgcolor: "#1F2A40",
    outline: "none",
    boxShadow: 24,
    p: 2,
  };

  const handleFormSubmit = async(values) => {
    console.log(values);
    await dispatch(addNewRoom(values));
    handleClose();
  };
  const initialValues = {
    email: "",
    name: "",
  };
  const checkoutSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Required"),
  });
  
  const columns = [
    { field: "_id", headerName: "User Id", width: 200 },
    { field: "username", headerName: "Username", width: 150, cellClassName: "name-column--cell" },
    { field: "email", headerName: "email", width: 250 },
    {
      field: "is_active",
      headerName: "Action",
      width: 100,
      renderCell: ({ row: { username } }) => {
        return (
          <Button variant="contained" onClick={() => window.open(`https://mybingo.social/${username}`)}>
            Open Card
          </Button>
        );
      },
    },
  ];
  return (
    <Box m="20px">
      <Box         
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
        m="20px 0"
      >
        <Header title="USERS" subtitle="welcome to Users" />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={handleOpen}
          >
            <Add sx={{ mr: "10px" }} />
            Add new user
          </Button>
        </Box>
      </Box>
      <Box
        m="8px 0 0 0"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid rows={roomsList} columns={columns} getRowId={(row) => row?._id}/>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={modalStyle}>
          <Header title="ADD NEW USER" titleVariant="h4" />

          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>

                <Stack spacing={2}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="filled"
                    type="email"
                    label="Email of the user"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    error={!!touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    variant="filled"
                    type="text"
                    label="Name (optional)"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    name="name"
                    error={!!touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                  />
                  {/* <TextField
                    fullWidth
                    size="small"
                    variant="filled"
                    type="number"
                    label="Minimum number of people"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.minNumOfPeople}
                    name="minNumOfPeople"
                    error={!!touched.minNumOfPeople && !!errors.minNumOfPeople}
                    helperText={touched.minNumOfPeople && errors.minNumOfPeople}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    variant="filled"
                    type="number"
                    label="Maximum number of people"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.maxNumOfPeople}
                    name="maxNumOfPeople"
                    error={!!touched.maxNumOfPeople && !!errors.maxNumOfPeople}
                    helperText={touched.maxNumOfPeople && errors.maxNumOfPeople}
                  />
                  <FormControl size="small" fullWidth>
                    <InputLabel>Highlights</InputLabel>
                    <Select
                      multiple
                      value={selections}
                      label="Highlights"
                      onChange={(e) => setSelections(e.target.value)}
                    >
                      {highlights?.list?.map((e, i) => (
                        <MenuItem value={e._id} key={i}>
                          <Checkbox checked={selections.indexOf(e._id) > -1} />
                          {e.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  {/* <FormControl size="small" fullWidth>
                    <InputLabel>Facilities</InputLabel>
                    <Select
                      multiple
                      value={selectedFacilities}
                      label="Facilities"
                      onChange={(e) => setSelectedFacilities(e.target.value)}
                    >
                      {facilities?.list?.map((e, i) => (
                        <MenuItem value={e._id} key={i}>
                          <Checkbox checked={selectedFacilities.indexOf(e._id) > -1} />
                          {e.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{ fontSize: "15px" }}
                  >
                    {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Submit</b>}
                  </Button>
                </Stack>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </Box>
  );
};

export default Rooms;
