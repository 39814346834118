export const ADD_NEW_HIGHLIGHT = `mutation ($name: String!, $icon: String!) {
    add_new_highlight(name: $name, icon: $icon){
      message
    }
  }
`;

export const ACTIVATE_HIGHLIGHT = `mutation ($id : String!) {
    activate_highlight(_id: $id){
      message
    }
  }
`;

export const LIST_ALL_HIGHLIGHTS = `query ($pageSize: Int, $pageNumber: Int) {
    list_all_highlights(page_size: $pageSize, page_number: $pageNumber){
      message
      data {
        _id
        name
        icon
        is_active
      }
      hasMore
    }
  }
`;

  export const ADD_NEW_BOOKING = `mutation ($users: [String]!, $prizes: [String]) {
  host_a_game(users: $users, prizes: $prizes){
    message
  }
}
`;

export const LIST_ALL_BOOKINGS = `query{
  list_all_games{
    message
    data {
      _id
      status
    }
  }
}
`; 

export const ADD_NEW_USER = `mutation ($email: String!) {
  create_user(email: $email){
    message
  }
}
`;

export const LIST_ALL_USERS = `query{
  list_all_users{
    message
    data {
      _id
      email
      username
    }
  }
}
`;

export const ADD_NEW_FACILITY = `mutation ($name: String!, $icon: String!) {
    add_new_facility(name: $name, icon: $icon){
      message
    }
  }
`;


export const LIST_ALL_FACILITIES = `query ($pageSize: Int, $pageNumber: Int) {
    list_all_facilities(page_size: $pageSize, page_number: $pageNumber){
      message
      data {
        _id
        name
        icon
        is_active
      }
      hasMore
    }
  }
`;