import { post } from "../../services";
import {
    ADD_NEW_BOOKING,
    ADD_NEW_FACILITY,
    ADD_NEW_HIGHLIGHT,
    ADD_NEW_USER,
    LIST_ALL_BOOKINGS,
    LIST_ALL_FACILITIES,
    LIST_ALL_HIGHLIGHTS,
    LIST_ALL_USERS,
} from "../../graphql";
import {
    RESET_ALL_BOOKINGS,
    RESET_ALL_FACILITIES_DATA,
    RESET_ALL_HIGHLIGHTS_DATA,
    RESET_ALL_ROOMS_LIST,
    SAVE_ALL_BOOKINGS,
    SAVE_ALL_FACILITIES,
    SAVE_ALL_HIGHLIGHTS,
    SAVE_ALL_ROOMS_LIST,
    TOGGLE_LOADING,
} from "../types";

export const addNewHighlight = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ADD_NEW_HIGHLIGHT,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllHighlightsData());
            dispatch(listAllHighlights());
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAllHighlights = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const {
            highlights: { pageSize, pageNumber, list },
        } = getState().admin;

        let apiResponse = await post("", {
            query: LIST_ALL_HIGHLIGHTS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_highlights: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: SAVE_ALL_HIGHLIGHTS,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllHighlightsData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_HIGHLIGHTS_DATA });
    };
};

export const addNewFacility = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ADD_NEW_FACILITY,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllFacilitiesData());
            dispatch(listAllFacilities());
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAllFacilities = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const {
            facilities: { pageSize, pageNumber, list },
        } = getState().admin;

        let apiResponse = await post("", {
            query: LIST_ALL_FACILITIES,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_facilities: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: SAVE_ALL_FACILITIES,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllFacilitiesData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_FACILITIES_DATA });
    };
};

export const addNewBooking = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ADD_NEW_BOOKING,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllBookingsData());
            dispatch(listAllBookings());
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAllBookings = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            bookings: { pageSize, pageNumber, list },
        } = getState().admin;

        let apiResponse = await post("", {
            query: LIST_ALL_BOOKINGS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_games: { data, hasMore },
            } = apiResponse.data.data;
            let updatedList = [...list, ...data];

            dispatch({
                type: SAVE_ALL_BOOKINGS,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllBookingsData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_BOOKINGS });
    };
};

export const addNewRoom = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ADD_NEW_USER,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllRoomsList());
            dispatch(listAllRooms());
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAllRooms = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            rooms: { pageSize, pageNumber, list },
        } = getState().admin;

        let apiResponse = await post("", {
            query: LIST_ALL_USERS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_users: { data, hasMore },
            } = apiResponse.data.data;
            let updatedList = [...list, ...data];
            // console.log(apiResponse);
            dispatch({
                type: SAVE_ALL_ROOMS_LIST,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllRoomsList = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_ROOMS_LIST });
    };
};
