import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store/store";
import AdminLayout from "./layouts/admin";
import Login from "./pages/auth";

const App = () => {
  const [theme, colorMode] = useMode();
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        // hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Routes>
                <Route path="/*" element={<AdminLayout />} />
                <Route path="/login" element={<Login />} />
              </Routes>
            </ThemeProvider>
          </ColorModeContext.Provider>
        </PersistGate>
      </Provider>
    </>
  );
};

export default App;
