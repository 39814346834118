export const LOGIN_WITH_OTP = `query ($email: String!, $otp: String!) {
  login_with_otp(email: $email, otp: $otp) {
      message
      token
    }
  }
  `;

export const SEND_OTP = `mutation ($email: String!) {
    send_otp(email: $email) {
      message
    }
  }
  `;