import {
    SAVE_ALL_HIGHLIGHTS,
    RESET_ALL_HIGHLIGHTS_DATA,
    SAVE_ALL_BOOKINGS,
    RESET_ALL_BOOKINGS,
    SAVE_ALL_ROOMS_LIST,
    RESET_ALL_ROOMS_LIST,
    SAVE_ALL_FACILITIES,
    RESET_ALL_FACILITIES_DATA
} from "../types";

const INITIAL_STATE = {
    highlights: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 10,
    },
    facilities: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 10,
    },
    bookings: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 10,
    },
    rooms: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 10,
    }
};

const adminReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SAVE_ALL_HIGHLIGHTS:
            return {
                ...state,
                highlights: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ALL_HIGHLIGHTS_DATA:
            return {
                ...state,
                highlights: INITIAL_STATE.highlights,
            };

        case SAVE_ALL_FACILITIES:
            return {
                ...state,
                facilities: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ALL_FACILITIES_DATA:
            return {
                ...state,
                facilities: INITIAL_STATE.facilities,
            };

        case SAVE_ALL_BOOKINGS:
            return {
                ...state,
                bookings: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ALL_BOOKINGS:
            return {
                ...state,
                bookings: INITIAL_STATE.bookings,
            };

        case SAVE_ALL_ROOMS_LIST:
            return {
                ...state,
                rooms: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ALL_ROOMS_LIST:
            return {
                ...state,
                rooms: INITIAL_STATE.rooms,
            };

        default:
            return state;
    }
};

export default adminReducer;
