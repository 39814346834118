export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";

export const SAVE_ALL_HIGHLIGHTS = "SAVE_ALL_HIGHLIGHTS";
export const RESET_ALL_HIGHLIGHTS_DATA = "RESET_ALL_HIGHLIGHTS_DATA";

export const SAVE_ALL_FACILITIES = "SAVE_ALL_FACILITIES";
export const RESET_ALL_FACILITIES_DATA = "RESET_ALL_FACILITIES_DATA";

export const SAVE_ALL_BOOKINGS = "SAVE_ALL_BOOKINGS";
export const RESET_ALL_BOOKINGS = "RESET_ALL_BOOKINGS";

export const SAVE_ALL_ROOMS_LIST = "SAVE_ALL_ROOMS_LIST";
export const RESET_ALL_ROOMS_LIST = "RESET_ALL_ROOMS_LIST";